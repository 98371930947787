<template>
	<div
		v-if="step === currentStep"
		class="form__step"
		:class="blockClass"
		ref="step"
	>
		<slot></slot>
		<div v-if="actions" class="form__step__actions">
			<button
				@click="$emit('next')"
				class="btn btn--primary"
				:disabled="!(isValid || !required)"
			>
				{{ $t('Continuer') }}
			</button>
		</div>
	</div>
</template>
<script>
export default {
	name: 'contactStep',
	props: {
		blockClass: String,
		step: {
			type: Number,
			required: true
		},
		currentStep: {
			type: Number,
			required: true
		},
		actions: {
			type: Boolean,
			default: true
		},
		value: Object,
		requiredValues: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			inputs: []
		}
	},
	computed: {
		stepObject: {
			get() {
				return this.value // ? this.value : [] //this.test
			},
			set(value) {
				this.$emit('input', value)
			}
		},
		isValid() {
			if (!this.required) {
				this.stepObject.isValid = true
			} else {
				this.checkValues()
			}
			return this.stepObject.isValid
		},
		required() {
			return this.stepObject.required
		}
	},
	watch: {
		requiredValues: {
			handler() {
				this.checkValues()
			},
			deep: true
		},
		// currentStep(newStep) {
		// 	if (newStep === this.step)
		// 	{
		// 		this.buildInputsArray()
		// 	}
		// }
	},
	methods: {
		checkValues() {
			let status = true
			for (const objectValue of this.requiredValues) {
				if (!objectValue.value) {
					status = false
					break
				}
			}
			this.stepObject.isValid = status
			return status
		},
		buildInputsArray() {
			if (this.$el instanceof HTMLElement)
			{
				this.inputs = []
				const names = this.$el.querySelectorAll('[name]')
				for (const name of names)
				{
					this.inputs.push(name.getAttribute('name'))
				}
			}
		}
	},
	mounted() {
		this.checkValues()
		this.buildInputsArray()
		this.$root.$on('MODALCONTACT_FORM_ERROR', ({ errors }) => {
			for (const keyError in errors)
			{
				const input = this.inputs.find(input => input === keyError)
				if (input)
				{
					this.$root.$emit('MODALCONTACT_STEP_CHANGE', {step: this.step})
				}
			}
		})
	},
	updated() {
		this.$nextTick(() => {
			this.buildInputsArray()
		})
	}
}
</script>