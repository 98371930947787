<template>
	<div
		:class="[{'form__field--error': displayError}, 'form__field']"
	>
		<div
			class="field__group"
			:class="{'field--secondary': isSecondary}"
		>
			<input
				@input="$emit('input', $event.target.value)"
				@keyup="cleanSpace"
				@keydown="checkValue"
				@blur="hadContact = true"
				:value="value"
				:id="fieldId"
				:name="id"
				:required="required"
				:type="type"
				:class="{'is-filled': value}"
			>
			<label :for="fieldId" class="field__label">{{ label }}</label>
			<div class="field__group__placeholder">{{ placeholder }} <small v-if="!required">({{ $t('facultatif') }})</small></div>
		</div>
		<div v-if="displayError && value" class="form__field__error">
			<p class="mt-0 mb-0">{{ $t('Numéro incorrect') }}</p>
		</div>
		<div v-else-if="errors || displayError" class="form__field__error">
			<p v-if="displayError" class="mt-0 mb-0">{{ $t('Ce champ est requis') }}</p>
			<p v-for="error in errors" class="mt-0 mb-0">{{ error }}</p>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'FieldText',
		props: {
			id: {
				type: String,
				required: true
			},
			required: {
				type: Boolean,
				default: false
			},
			isSecondary: {
				type: Boolean,
				default: false
			},
			type: {
				type: String,
				default: 'text'
			},
			value: String,
			label: String,
			errors: Array,
			placeholder: String
		},
		data() {
			return {
				hadContact: false
			}
		},
		computed: {
			fieldId() {
				return `field__${this.id}`
			},
			displayError() {
				if (this.type === 'tel' && this.hadContact)
				{
					if (this.required)
					{
						return !this.value || !this.isPhoneSubmitable
					}
					else
					{
						return !!(this.value && !this.isPhoneSubmitable)
					}
				}
				else if (this.required && this.hadContact)
				{
					if (!this.value) {
						return true
					}
				}
				return false
			},
			isPhoneSubmitable() {
				// const rule = /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/gmi;
				const rule = /^(?:(?:\+|00)[1-9]{2,4}|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gmi;
				return rule.test(this.value)
			}
		},
		methods: {
			cleanSpace() {
				if (this.type === 'tel')
				{
					if (!this.value) return
					const cleanValue = this.value.replace(/\s/g, '')
					this.$emit('input', cleanValue)
				}
			},
			checkValue(event)
			{
				if (this.type === 'tel')
				{
					if (
						['0','1','2','3','4','5','6','7','8','9','+','Backspace','Delete','ArrowLeft','ArrowRight'].indexOf(event.key) < 0
						&& !event.metaKey
						&& !event.ctrlKey
					)
					{
						event.preventDefault()
					}
				}
			}
		},
		mounted() {
			if (this.value)
			{
				this.hadContact = true
			}
		}
	}
</script>