<template>
	<div class="form__section">
		<div class="form__row">
			<field-text
				v-if="isPro"
				id="company"
				v-model="value.company"
				label="Société"
				placeholder="Nom de la société"
				:errors="errors.company"
			/>
		</div>
		<div class="form__row">
			<field-text
				id="firstName"
				v-model="value.firstName"
				label="Prénom"
				placeholder="Votre prénom"
				:errors="errors.firstName"
				:required="true"
			/>
			<field-text
				id="lastName"
				v-model="value.lastName"
				label="Nom"
				placeholder="Votre nom"
				:errors="errors.lastName"
				:required="true"
			/>
		</div>
		<div class="form__row">
			<field-text
				id="email"
				v-model="value.email"
				label="Email"
				placeholder="Votre adresse email"
				:errors="errors.email"
				:required="true"
				type="email"
			/>
			<field-text
				id="phone"
				v-model="value.phone"
				label="Téléphone"
				placeholder="01 02 03 04 05"
				:errors="errors.phone"
				type="tel"
			/>
		</div>
		<div class="form__row">
			<field-text
				id="address"
				v-model="value.address"
				label="Adresse"
				placeholder="Votre adresse postale"
				:errors="errors.address"
			/>
		</div>
		<div class="form__row">
			<field-text
				id="city"
				v-model="value.city"
				label="Ville"
				placeholder="Votre ville"
				:errors="errors.city"
			/>
			<field-text
				id="zipcode"
				v-model="value.zipcode"
				label="Code postal"
				placeholder="Votre code postal"
				:errors="errors.zipcode"
				type="number"
			/>
		</div>
<!--		<div class="form__row">-->
<!--			<field-textarea-->
<!--				id="message"-->
<!--				v-model="value.message"-->
<!--				label="Message"-->
<!--				:rows="8"-->
<!--				:errors="errors.email"-->
<!--				placeholder="Précision sur votre demande"-->
<!--			/>-->
<!--		</div>-->
	</div>
</template>
<script>
import FieldText from "ThemeSrc/js/vue/components/FieldText";
import FieldTextarea from "ThemeSrc/js/vue/components/FieldTextarea";

export default {
	name: 'contactPartBase',
	components: {FieldText, FieldTextarea},
	props: {
		value: Object,
		errors: Object,
		loading: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		isPro() {
			return this.value.profile === 'professional'
		}
	}
}
</script>