<template>
	<div
		:class="[{'form__field--error': displayError}, 'form__field']"
	>
		<label
			class="field__checkbox"
			:class="{'field--secondary': isSecondary}"
			:for="fieldId"
		>
			<input
				v-model="checked"
				:value="1"
				:id="fieldId"
				:name="id"
				:required="required"
				type="checkbox"
			>
			<span class="checkbox"></span>
			<span class="field__label">{{ label }} <small v-if="!required">({{ $t('facultatif') }})</small></span>
			<div v-if="errors || displayError" class="form__field__error">
				<p v-if="displayError" class="mt-0 mb-0">{{ $t('Ce champ est requis') }}</p>
				<p v-for="error in errors" class="mt-0 mb-0">{{ error }}</p>
			</div>
		</label>
	</div>
</template>
<script>
	export default {
		name: 'FieldCheckbox',
		props: {
			id: {
				type: String,
				required: true
			},
			required: {
				type: Boolean,
				default: false
			},
			isSecondary: {
				type: Boolean,
				default: false
			},
			value: Boolean,
			label: String,
			errors: Array
		},
		data() {
			return {
				checkedProxy: false
			}
		},
		computed: {
			checked: {
				get() {
					return this.value
				},
				set(value) {
					this.$emit('input', value)
				}
			},
			fieldId() {
				return `field__${this.id}`
			},
			displayError() {
				if (this.required && this.hadContact) {
					if (!this.value) {
						return true
					}
				}
				return false
			}
		}
	}
</script>