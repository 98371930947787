<template>
	<div
		:class="[{'form__field--error': displayError}, 'form__field']"
	>
		<div class="field__group field__group--textarea">
			<textarea
				@input="$emit('input', $event.target.value)"
				:value="value"
				:id="fieldId"
				:name="id"
				:rows="rows"
				:required="required"
				:class="{'is-filled': value}"
				type="text"
			></textarea>
			<label :for="fieldId" class="field__label">{{ label }}</label>
			<div class="field__group__placeholder">{{ placeholder }} <small v-if="!required">(facultatif)</small></div>
		</div>
		<div v-if="errors || displayError" class="form__field__error">
			<p v-if="displayError" class="mt-0 mb-0">{{ $t('Ce champ est requis') }}</p>
			<p v-for="error in errors" class="mt-0 mb-0">{{ error }}</p>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'FieldTextarea',
		props: {
			id: {
				type: String,
				required: true
			},
			required: {
				type: Boolean,
				default: false
			},
			isSecondary: {
				type: Boolean,
				default: false
			},
			value: String,
			label: String,
			errors: Array,
			placeholder: String,
			rows: Number
		},
		computed: {
			fieldId() {
				return `field__${this.id}`
			},
			displayError() {
				if (this.required && this.hadContact) {
					if (!this.value) {
						return true
					}
				}
				return false
			}
		}
	}
</script>