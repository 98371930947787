<template>
	<div class="form__field">
		<div class="field__group">
			<table class="field__table">
				<tr class="field__table__head">
					<th>#</th>
					<th v-for="col in cols">
						{{ col.title }}
					</th>
					<th></th>
				</tr>
				<tbody>
					<tr v-for="(row, index) in rows">
						<td>{{ index + 1 }}</td>
						<td
							v-for="col in cols"
							class="table-cell table-cell--input"
						>
							<input
								v-model="row[col.slug]"
								:type="col.field && col.field.type ? col.field.type : 'text'"
								:placeholder="col.field ? col.field.placeholder : null"
							>
						</td>
						<td
							v-if="displayAction(index)"
							@click="removeRow(index)"
						    class="table-cell__action"
						>
							<icon id="cross" width="20" height="20"/>
						</td>
						<td v-else></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'FieldRepeaterTable',
		props: {
			required: {
				type: Boolean,
				default: false
			},
			cols: {
				type: Array,
				required: true
			},
			id: String,
			value: Array,
			errors: Array,
		},
		data() {
			return {
				hadContact: false
			}
		},
		watch: {
			rows: {
				handler(newValues)
				{
					this.checkValues(newValues)
				},
				deep: true
			}
		},
		computed: {
			rows: {
				get() {
					return this.value // ? this.value : [] //this.test
				},
				set(value) {
					this.$emit('input', value)
				}
			},
			displayError() {
				if (this.required && this.hadContact) {
					if (!this.value) {
						return true
					}
				}
				return false
			}
		},
		methods: {
			checkValues(newValues)
			{
				let hasValue = false

				if (newValues && newValues.length > 0)
				{
					const last = newValues[newValues.length - 1]
					for (const [key, value] of Object.entries(last)) {
						if (value || value !== '') {
							hasValue = true
						}
					}

					if (hasValue)
					{
						this.rows.push({})
					}
				}
				else {
					this.rows = [{}]
				}
			},
			removeRow(index)
			{
				this.rows.splice(index, 1)
			},
			displayAction(index)
			{
				return (index + 1) < this.rows.length
			}
		},
		mounted() {
			this.checkValues(this.rows)
		}
	}
</script>
<!--<style scoped lang="scss">-->
<!--.table-cell__actions {-->
<!--	display: flex;-->
<!--	-->
<!--}-->
<!--</style>-->