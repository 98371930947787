<template>
	<div class="form__step__actions">
		<button
			@click="$emit('submit')"
			:disabled="!isSubmitable || loading || !isAllStepsValid"
			class="btn btn--primary"
		>
			{{ $t('envoyer ma demande') }}
		</button>
	</div>
</template>
<script>
export default {
	name: 'contactSubmit',
	props: {
		steps: {
			type: Array,
			required: true
		},
		isSubmitable: {
			type: Boolean,
			required: true
		},
		loading: {
			type: Boolean,
			required: true
		},
		stepPath: {
			type: Array,
			required: true
		}
	},
	computed: {
		isAllStepsValid() {
			let status = true
			for (const stepNumber of this.stepPath) {
				const step = this.steps.find(s => s.number === stepNumber)
				if (!step) continue
				if (!step.isValid) {
					status = false
					break
				}
			}
			return status
		}
	}
}
</script>