<template>
	<div class="form__steps">
		<div
			@click="$emit('prev')"
			class="form__steps__action prev"
			:class="{'is-active': currentStep > 1}"
		>
			<icon id="caret-left" width="6" height="12" view-box="0 0 6 12"/>
		</div>
		<span class="text--label text--label--simple">{{ countSteps }}</span>
		<div
			@click="$emit('next')"
			class="form__steps__action next"
			:class="{'is-active': currentStep < steps.length && (!currentStepObject.required || currentStepObject.isValid)}"
		>
			<icon id="caret-right" width="6" height="12" view-box="0 0 6 12"/>
		</div>
	</div>
</template>
<script>
export default {
	name: 'contactStepNav',
	props: {
		steps: {
			type: Array,
			required: true
		},
		currentStep: {
			type: Number,
			required: true
		},
		countSteps: {
			type: String,
			required: true
		},
		currentStepObject: {
			type: Object,
			required: true
		}
	}
}
</script>