<template>
	<div class="form__section">
		<div class="form__row">
			<field-group-radio
				@click="$emit('click')"
				v-model="value.profile"
				id="profile"
				:required="true"
				:options="[
					{
						value: 'professional',
						title: $t('Pro'),
						overTitle: $t('Je suis un')
					},
					{
						value: 'individual',
						title: $t('Particulier'),
						overTitle: $t('Je suis un')
					}
				]"
			/>
		</div>
	</div>
</template>
<script>
import FieldGroupRadio from "ThemeSrc/js/vue/components/FieldGroupRadio";

export default {
	name: 'contactProfile',
	components: {FieldGroupRadio},
	props: {
		value: Object,
		errors: Object,
		loading: {
			type: Boolean,
			default: false
		}
	}
}
</script>