var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form__section"},[_c('div',{staticClass:"form__row"},[(!_vm.value.typeEstimate || _vm.value.typeEstimate === 'custom')?_c('field-group-radio',{attrs:{"id":"profile","required":true,"options":[
					{
						value: 'catalog',
						title: _vm.$t('Catalogue'),
						// overTitle: $t('Catalogue')
					},
					{
						value: 'custom',
						title: _vm.$t('Sur-mesure'),
						// overTitle: $t('Je suis un')
					}
				]},on:{"click":_vm.onClick},model:{value:(_vm.value.typeEstimate),callback:function ($$v) {_vm.$set(_vm.value, "typeEstimate", $$v)},expression:"value.typeEstimate"}}):_vm._e()],1),_vm._v(" "),(_vm.value.typeEstimate === 'catalog')?[_c('div',{staticClass:"form__row"},[_c('field-repeater-table',{attrs:{"id":"ref","required":true,"cols":[
						{
							title: _vm.$t('Référence'),
							slug: 'ref',
							field: {
								placeholder: 'Réf. produit'
							}
						},
						{
							title: _vm.$t('Quantité'),
							slug: 'qty',
							field: {
								type: 'number',
								placeholder: '0'
							}
						}
					]},model:{value:(_vm.value.productRefs),callback:function ($$v) {_vm.$set(_vm.value, "productRefs", $$v)},expression:"value.productRefs"}})],1),_vm._v(" "),_c('div',{staticClass:"form__step__actions justify-content-between"},[_c('button',{staticClass:"btn btn--primary btn--outline",on:{"click":function($event){_vm.value.typeEstimate = ''}}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('Modifier'))+"\n\t\t\t\t")]),_vm._v(" "),_c('button',{staticClass:"btn btn--primary",on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('Continuer'))+"\n\t\t\t\t")])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }