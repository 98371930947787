<template>
	<div class="form__field">
		<div class="field__group field__group--radio">
			<label
				@click="click(option.value)"
				v-for="option in options"
				:for="`field__radio__${id}__${option.value}`"
				class="field__radio"
				:class="{'is-selected': value === option.value}"
			>
				<input
					v-model="checked"
					:value="option.value"
					:name="id"
					type="radio"
					:id="`field__radio__${id}__${option.value}`"
				>
				<div class="field__radio__wrapper">
					<div class="field__radio__box"></div>
					<div class="field__label">
						<small>{{ option.overTitle }}</small>
						{{ option.title }}
					</div>
				</div>
			</label>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'FieldGroupRadio',
		props: {
			required: {
				type: Boolean,
				default: false
			},
			options: {
				type: Array,
				required: true
			},
			id: String,
			value: String,
			errors: Array,
		},
		data() {
			return {
				hadContact: false
			}
		},
		computed: {
			checked: {
				get() {
					return this.value
				},
				set(value) {
					this.$emit('input', value)
					this.$emit('click', {value})
				}
			},
			fieldId() {
				return `field__${this.id}`
			},
			displayError() {
				if (this.required && this.hadContact) {
					if (!this.value) {
						return true
					}
				}
				return false
			}
		},
		methods: {
			click(newValue) {
				if (newValue === this.value) {
					this.$emit('click', {value: newValue})
				}
			}
		}
	}
</script>