<template>
	<div
		class="form__section"
	>
		<div class="form__row">
<!--				@click="$emit('click')"-->
			<field-group-radio
				@click="onClick"
				v-if="!value.typeEstimate || value.typeEstimate === 'custom'"
				v-model="value.typeEstimate"
				id="profile"
				:required="true"
				:options="[
					{
						value: 'catalog',
						title: $t('Catalogue'),
						// overTitle: $t('Catalogue')
					},
					{
						value: 'custom',
						title: $t('Sur-mesure'),
						// overTitle: $t('Je suis un')
					}
				]"
			/>
		</div>
		<template
			v-if="value.typeEstimate === 'catalog'"
		>
			<div class="form__row">
				<field-repeater-table
					v-model="value.productRefs"
					id="ref"
					:required="true"
					:cols="[
						{
							title: $t('Référence'),
							slug: 'ref',
							field: {
								placeholder: 'Réf. produit'
							}
						},
						{
							title: $t('Quantité'),
							slug: 'qty',
							field: {
								type: 'number',
								placeholder: '0'
							}
						}
					]"
				/>
			</div>
			<div class="form__step__actions justify-content-between">
				<button
					@click="value.typeEstimate = ''"
					class="btn btn--primary btn--outline"
				>
					{{ $t('Modifier') }}
				</button>
<!--						v-if="isValid || !required"-->
				<button
					@click="$emit('click')"
					class="btn btn--primary"
				>
					{{ $t('Continuer') }}
				</button>
			</div>
		</template>
	</div>
</template>
<script>
import FieldGroupRadio from "ThemeSrc/js/vue/components/FieldGroupRadio";
import FieldRepeaterTable from "ThemeSrc/js/vue/components/FieldRepeaterTable";

export default {
	name: 'contactCatalog',
	components: {FieldRepeaterTable, FieldGroupRadio},
	props: {
		value: Object,
		errors: Object,
		loading: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		'value.typeEstimate': {
			handler(newValue) {
				if (newValue === 'custom') {
					this.$emit('click')
				}
			}
		}
	},
	methods: {
		onClick(event) {
			if (event.value === 'custom') {
				this.$emit('click')
			}
		}
	}
}
</script>