var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form__section"},[_c('div',{staticClass:"form__row"},[_c('field-group-radio',{attrs:{"id":"profile","required":true,"options":[
				{
					value: 'professional',
					title: _vm.$t('Pro'),
					overTitle: _vm.$t('Je suis un')
				},
				{
					value: 'individual',
					title: _vm.$t('Particulier'),
					overTitle: _vm.$t('Je suis un')
				}
			]},on:{"click":function($event){return _vm.$emit('click')}},model:{value:(_vm.value.profile),callback:function ($$v) {_vm.$set(_vm.value, "profile", $$v)},expression:"value.profile"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }