<template>
	<div class="form__section">
		<div class="form__row">
			<field-textarea
				id="message"
				v-model="value.message"
				:label="fieldLabel"
				:rows="8"
				:errors="errors.message"
				:placeholder="fieldLabel"
				:required="requireMessage"
			/>
		</div>
		<div class="form__row">
			<field-checkbox
				id="legal"
				v-model="value.legal"
				:label="legalLabel"
				:errors="errors.legal"
				:required="true"
			/>
		</div>
		<div v-if="displayNewsletter" class="form__row">
			<field-checkbox
				id="newsletter"
				v-model="value.newsletter"
				label="En cochant cette case, j’accepte de recevoir des conseils et actualités par e-mail, en moyenne une fois par mois."
				:errors="errors.newsletter"
			/>
		</div>
	</div>
</template>
<script>
import FieldText from "ThemeSrc/js/vue/components/FieldText";
import FieldTextarea from "ThemeSrc/js/vue/components/FieldTextarea";
import FieldCheckbox from "ThemeSrc/js/vue/components/FieldCheckbox";

export default {
	name: 'contactDetails',
	components: {FieldCheckbox, FieldText, FieldTextarea},
	props: {
		value: Object,
		errors: Object,
		fieldLabel: {
			type: String,
			default: 'Votre message'
		},
		legalLabel: {
			type: String,
			default: 'En cochant cette case, j’accepte d’être contacté par mail ou par téléphone, dans le cadre de ma demande.'
		},
		loading: {
			type: Boolean,
			default: false
		},
		displayNewsletter: {
			type: Boolean,
			default: true
		},
		requireMessage: {
			type: Boolean,
			default: false
		}
	},
	computed: {

	}
}
</script>